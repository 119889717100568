import './App.css';
import React from 'react';
import {Button} from '@mui/material';

function App() {
  return (
    <div className="App">
      <Button variant="contained">Hello world</Button>
    </div>
  );
}

export default App;
